@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");

#main.force {
  background: black;

  h1.may4 {
    color: white;
    font-family: "Press Start 2P", cursive;
    font-size: 1em;
    text-align: center;
  }

  section > h1 {
    color: white;
    text-shadow: none;
  }

  section > h2 {
    color: white;
    text-shadow: none;
  }

  a {
    color: #b20014;
  }

  .mui-appbar {
    background-color: #fc690a !important;
    color: #fff;

    .icons i {
      color: white;
    }

    .icons a {
      color: white;
    }
  }

  .mui-panel {
    background-color: #222;
    color: white;
  }

  .account-tag {
    background-color: black;
  }

  .tag {
    color: #b20014;
  }

  .api-link {
    background-color: #7e0010;
    color: white;
  }

  .recharts-wrapper {
    background-color: white;
  }

  footer {
    background-color: black;
    color: white;
  }

  @mixin glow($color) {
    @keyframes glow_#{$color} {
      from {
        color: #{$color};
        box-shadow: 0 0 9px #{$color};
      }
      50% {
        color: #{$color};
        box-shadow: 0 0 50px #{$color};
      }
      to {
        color: #{$color};
        box-shadow: 0 0 9px #{$color};
      }
    }
  }

  @include glow("lime");
  @include glow("orange");
  @include glow("red");
  @include glow("gray");

  .pulse-container {
    width: 180px;
    height: 30px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;

    .pulse {
      opacity: 1;
      width: 180px;
      height: 9px;
      background-color: white;

      &.pulse1 {
        animation: glow_lime 1.5s linear infinite;
        box-shadow: 0px 0px 10px, 0px 0px 2px inset;
        border-top-left-radius: 0;
        border-top-right-radius: 20px 10px;
        border-bottom-right-radius: 20px 10px;
        border-bottom-left-radius: 0;
      }

      &.pulse2 {
        animation: none;
        height: 5px;
        margin-top: 2px;
        width: 30px;
        margin-left: -30px;
        border-right: 1px solid black;

        border-top-left-radius: 2px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 2px;

        @include prefixer(
          background,
          linear-gradient(
            to right,
            #fff 0%,
            #999 10%,
            #fff 20%,
            #999 30%,
            #fff 40%,
            #999 50%,
            #fff 70%,
            #999 70%,
            #fff 80%,
            #999 90%,
            #fff 100%
          ),
          webkit moz spec
        );
      }

      &.pulse1.slow {
        animation: glow_orange 1.5s linear infinite;
        background-color: #fca30a;
      }

      &.pulse1.very-slow {
        animation: glow_red 1.5s linear infinite;
        background-color: red;
      }

      &.pulse1.down {
        animation: glow_gray 1.5s linear infinite;
        background-color: black;
      }
    }
  }
}
