// body {
//   background-color: #eeeeee;
//   font-family: "Roboto", "Helvetica Neue", Helvetica, Arial;
$background-color-dark: #323b44;
$background-color-light: #f1f5f9;
$complimentary-color-dark: #94a0ad;
$complimentary-color-light: #343a40;

body {
  margin: 0;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: $complimentary-color-light;
  text-align: left;
  background-color: $background-color-light;
}
body.dark-mode {
  background-color: $background-color-dark;
  color: $complimentary-color-dark;
  .card {
    background-color: #36404a;
    background-clip: border-box;
    border: 0 solid #36404a;
    box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  }
  .table {
    color: #94a0ad;
  }
  .table td,
  .table th {
    border-top: 1px solid #424e5a;
  }
  .table-hover tbody tr:hover {
    color: #94a0ad;
    background-color: #3b4651;
  }
  .text-orange {
    color: #acbfd2;
  }
  .api-link {
    background-color: #313130;
  }
  .api-linkk {
    background-color: #313130;
  }
  .topnav {
    background: #3d4752;
    box-shadow: 0 0 35px 0 rgba(66, 72, 80, 0.15);
    height: 70px;
  // margin-top: 70px;
  padding: 0 calc(24px / 2);
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
  @media only screen and (max-width: 450px) {
    padding: 0;
  }
}
  .network-stats {
    background-color: #3d4752;
  }
  .btn-outline-dark {
    color: #94a0ad;
    border-color: #94a0ad;
    @media only screen and (max-width: 450px) {
      margin: -21px;
    }
  }
  footer {
    background-color: #3d4752;
  }
}
.topnav {
  background: #fc690a;
  box-shadow: 0 0 35px 0 rgba(66, 72, 80, 0.15);
  height: 70px;
  // margin-top: 70px;
  padding: 0 calc(24px / 2);
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
  @media only screen and (max-width: 450px) {
    padding: 0;
}

.btn-outline-dark {
 
  @media only screen and (max-width: 450px) {
   margin: -21px;
  }
}

}
.footer-side {
  padding-top: 10px;
  font-size: 12px;
}
.so-logo__separator {
  height: 1em;
  color: #ccc;
  margin: 0 8px;
  width: 1px;
  font-size: 18px;
  @media only screen and (max-width: 450px) {
    display: none;
  }
}
.brand-text {
  color: #fff;
  font-size: 20px;
  position: relative;
  top: 1px;
  text-decoration: none;
  @media only screen and (max-width: 450px) {
    font-size: 16px;
    top: 3px;
    padding-left: 8px;
  }
}
a {
  color: #43A047;
  text-decoration: none;
}
a:focus, a:hover {
  color: #43A047;
  text-decoration: none;
}
.nav-row-padding {
  padding-left: 57px;
  padding-right: 40px;

  @media only screen and (max-width: 450px) {
    padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  }
}
footer {
  background-color: white;
  font-size: 0.9em;
  padding: 15px 6px;
  height: 70px;
  width: 100% !important;
  display: block;
  @include prefixer(border-shadow, 0px 0px 20px 2px #dddddd, webkit moz spec);

  @media only screen and (max-width: 450px) {
    padding: 10px;
    text-align: center;
  }
}
@media only screen and (max-width: 450px) {
  .footer-side {
    padding-top: 0;
  }
}

section {
  clear: both;

  > h1 {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1em;
    font-weight: 600;
    text-shadow: 1px 1px white;
    margin-bottom: 0;
  }

  > h2 {
    color: #999;
    text-align: center;
    font-size: 0.9em;
    font-weight: 600;
    text-shadow: 1px 1px white;
    margin: 0 auto 25px auto;
    width: 60%;
    line-height: normal;

    @media only screen and (max-width: 768px) {
      width: 90%;
    }
    @media only screen and (max-width: 450px) {
      width: 100%;
    }
  }
}

.app-container {
  // width: 90%;
  margin: auto;

  @media only screen and (max-width: 450px) {
    width: 100%;
  }
}
.orange-text {
  color: #fc690a !important;
}
.mui-appbar {
  background-color: #fc690a !important;
  color: #fff;
  .mui--text-headline {
    margin-left: 20px;
    float: left;
  }

  a {
    color: #43A047;
    text-decoration: none;
  }

  .left {
    float: left;
  }

  .back {
    margin-left: 20px;
    font-size: 0.8em;
    margin-top: 15px;
  }

  .back a {
    color: #43A047;
  }

  .icons {
    float: right;
    margin-top: 8px;
    line-height: 15px;

    .icon {
      float: left;
      text-align: center;
      width: 100px;
    }
  }
}

.mui--bg-accent-light {
  &.mui-panel {
    margin-bottom: 0;
  }

  a {
    color: #43A047;
    text-decoration: underline;
  }
}

.mui--bg-accent {
  &.mui-panel {
    margin-bottom: 0;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }
}

.widget-name {
  text-transform: uppercase;

  .tag {
    color: rgb(33, 150, 243);
    font-size: 0.7em;
    font-weight: 500;
    padding-left: 2px;

    i.material-icons {
      font-size: 1.1em;
    }
  }
}

@media (min-width: 1367px) {
  .col-xl-4 {
    flex: 0 0 38%;
    max-width: 33%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1180px) {
  .widget-name .tag .hide-narrow-panel {
    display: none;
  }
}

.mui-table.small {
  font-size: 0.8em;
  margin-bottom: 0px;
}

.mui-table.small tbody > tr:hover {
  background-color: #f0f0f0;
}

.chart-labels {
  font-size: 0.8em;
}

.mui-table.small td,
.mui-table.small th {
  padding: 0px;
}
.social-icons {
  font-size: 22px;
  content: "\f099";
  color: #fc690a;
  padding: 5px;
  cursor: pointer;
  margin: 5px;
}
.row {
  clear: both;
}

.margin-top10 {
  margin-top: 10px;
}

.small {
  font-size: 0.8em;
}

.gray {
  color: #bbbbbb;
}

.clear {
  clear: both;
}
.table-responsive {
  display: block;
  width: 100%;
  height: 500px;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}
.table td,
.table th {
  padding: 1rem;
  vertical-align: top;
  border-top: 1px solid #edeff1;
}
.network-stats {
  background: #f5f5f5;
  padding: 10px;
  font-size: 12px;
  margin: 1 5px 1 5px !important;
}
.api-link-wrap {
  display: flex;
  justify-content: space-between;
}

.api-linkk {
  float: right;
  text-transform: uppercase;
  // font-size: 0.8em;
  // background-color: rgb(255, 111, 0);
  color: #ff6f00;
  padding: 2px;
  font-weight: bold;
  margin-top: 12px;
}
.api-link {
  float: right;
  text-transform: uppercase;
  font-size: 0.8em;
  // background-color: rgb(255, 111, 0);
  color: #ff6f00;
  padding: 2px;
  font-weight: bold;
}

.api-link:hover {
  color: #43A047;
  text-decoration: none;
}

.api-link:active {
  color: #43A047;
  text-decoration: none;
}

.amount-column {
  text-align: left;
}

.account {
  margin-left: 2px;
}

.account-tag {
  text-transform: uppercase;
  font-size: 0.8em;
  background-color: #eeeeee;
  padding: 2px;
}

.slick-slider {
  user-select: initial;
  -webkit-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
}

@keyframes anim_pulse {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.pulse-container {
  width: 150px;
  height: 150px;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  .pulse {
    background-color: #43A047;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    opacity: 0.6;
    position: absolute;
    top: 0px;
    left: 0px;

    &.pulse1 {
      animation: anim_pulse 2s ease-in-out 1s infinite both;
    }

    &.pulse2 {
      animation: anim_pulse 2s ease-in-out 0s infinite both;
    }

    &.pulse1.slow {
      animation: anim_pulse 4s ease-in-out 2s infinite both;
      background-color: #fca30a;
    }

    &.pulse2.slow {
      animation: anim_pulse 4s ease-in-out 0s infinite both;
      background-color: #fca30a;
    }

    &.pulse1.very-slow {
      animation: anim_pulse 10s ease-in-out 5s infinite both;
      background-color: red;
    }

    &.pulse2.very-slow {
      animation: anim_pulse 10s ease-in-out 0s infinite both;
      background-color: red;
    }

    &.pulse1.down {
      animation: anim_pulse 20s ease-in-out 10s infinite both;
      background-color: black;
    }

    &.pulse2.down {
      animation: anim_pulse 20s ease-in-out 0s infinite both;
      background-color: black;
    }
  }
}
.table-hover tbody tr:hover {
  color: #343a40;
  background-color: #f4f9fb;
}
.axis text {
  font-size: 10px;
}

.arc text {
  font-size: 10px;
}

.node-panel {
  padding: 0px !important;
  margin-bottom: 5px;

  i.dicon {
    position: absolute;
    color: #777;
    font-size: 18px;
  }

  .afterI {
    margin-left: 22px;
  }

  .uptime-great {
    font-weight: bold;
    color: #006450;
  }

  .uptime-good {
    color: #43A047;
  }

  .uptime-normal {
    color: #006450;
  }

  .uptime-bad {
    color: #cc0000;
  }
}

.node-panel-large {
  display: flex;
  align-items: center;
  min-height: 30px;
}

.node-circle-container {
  display: flex;
  align-items: center;
}

.node-circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  padding: 0 !important;
  margin-right: 10px;
}

.node-circle.blue {
  background-color: #43A047;
}

.node-circle.orange {
  background-color: #fca30a;
}

.node-circle.red {
  background-color: red;
}

.node-barchart-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.node-barchart-container .domain {
  display: none;
  visibility: hidden;
}

.node-barchart {
  padding-top: 5px !important;
}

.node-hovered-container {
  visibility: hidden;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  transition: all ease-out 100ms;
}

.node-pubkey {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 85%;
  height: 100%;
  font-family: monospace;
  font-size: 8pt;
  box-shadow: 1px 0px 7px 0px white;
}

.node-panel:hover .node-hovered-container {
  visibility: visible;
  transition: all ease-in 100ms;
}

.node-dropdown-button {
  cursor: pointer;
}

.node-dropdown {
  padding: 0px 10px;
}

.node-update-interval-container {
  padding-bottom: 5px;
}

ul.incidents {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 12px;
}
.mui--text-subhead {
  @media screen and (max-width: 500px) {
    font-size: 14px;
    line-height: 20px;
  }
}

section > h1 {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.5em;
  font-weight: 600;
  text-shadow: 1px 1px white;
  margin-bottom: 20px;
}
.card {
  margin-bottom: 24px !important;
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03) !important;
  border-radius: 8px !important;
}
.card-body {
  flex: 1 1 auto !important;
  min-height: 1px !important;
  padding: 1.5rem !important;
  
}
body[data-layout-mode="horizontal"] .navbar-custom {
  padding: 0;
  left: 0;
  right: 0;
}
// .navbar-custom {
//   background-color: #fc690a;
//   box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
//   padding: 0 10px 0 10px;
//   position: fixed;
//   left: 0;
//   right: 0;
//   top: 0;
//   height: 70px;
//   transition: all 0.1s ease-out;
//   z-index: 1001;
// }
@media (min-width: 1367px) {
  body[data-layout-mode="horizontal"] .container-fluid {
    max-width: 90%;
  }
}
.navbar-custom .container-fluid {
  padding: 0;
}

.float-right {
  float: right !important;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}

@media (min-width: 1367px) {
  body[data-layout-mode="horizontal"] .container-fluid {
    max-width: 90%;
  }
}
.navbar-custom .container-fluid {
  padding: 0;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}
.logo-box .logo {
  line-height: 64px;
}
.logo-dark {
  display: none;
}
.logo {
  display: block;
}
// .text-left {
//   color: #ffffff;
//   font-size: 32px;
//   text-align: left !important;
//   text-decoration: none;
// }
.text-center {
  text-align: center !important;
}
.text-muted {
  color: #93a4ad !important;
}
.text-mutedd {
  color: #6c757d !important;
}
.font-weight-normal {
  font-size: 20px;
  text-align: center;
  font-weight: 400 !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-orange {
  color: #fc690a;
  font-weight: 700;
  font-size: 32px;
}
.status-header {
  align-self: center;
}

.header-net-title {
  font-weight: 600;
  font-size: 24px;
}
.status-text {
  font-weight: 600;
  font-size: 18px;
}
.mode-btn {
  text-align: end !important;
  padding: 20px;
}

.mode-button {
  float: right;
}

// @media (max-width: 450px) {
//   .text-left {
//     font-size: 28px;
//   }
// }
@media (max-width: 450px) {
  .status-header {
    font-size: 20px;
    text-align: center;
  }
}
@media (max-width: 450px) {
  section > h1 {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1em;
    font-weight: 600;
    text-shadow: 1px 1px white;
  }
}
